import Reports from './Pages/Reports.svelte';
import Contact from './Pages/Contact.svelte';

const DefaultPage = Reports;
export const pages = [
  { path: '/', href: '/', name: 'Login', component: Reports, showInMainNav: false },
  { path: '/reports', href: '/reports', name: 'Reports', component: Reports, showInMainNav: true },
]

const address = (line1, city, state, zip5, mapLink) => ({ line1, city, state, zip5, mapLink, toString: () => `${line1}, ${city}, ${state}, ${zip5}`});
const site = ({
    name: 'BnBreezy',
    owner: 'BnBreezy',
    slogan: 'Short-Term Rental Scouting Made Easy',
    email: 'reinagelrentals@gmail.com',
    logo: './images/logo.png',
    logoMobile: './images/logo.png',
    address: address("123 Main Street", "City", "ST", "12345", ""),
    contactPrompt: 'Send us a message',
    social: {
        // twitter: '',
    }
  });
  

  export default site;