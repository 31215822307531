import { derived, get, readable, writable } from 'svelte/store';

const localStorage = window.localStorage;
const getUserFromLocalStorage =  () => {
  const userId = localStorage.getItem('UserId');
  return !!userId ? ({ userId, email: userId }) : null;
}
const initialUser = getUserFromLocalStorage();

const initialState = {
  user: initialUser
};

export const popupOpen = writable(false);
export const error = writable();

export const state = writable(initialState);
export const isLoggedIn = derived(state, $state => !!$state.user);
export const userId = derived(state, $state => !!$state.user ? $state.user.userId : null);
export const userEmail = derived(state, $state => !!$state.user ? $state.user.email : null);
export const userAvatar = derived(state, $state => !!$state.user ? $state.user.picture : null);
export default state;