<script>
  export let onClick = _ => {};
  export let text = 'Button';
  export let styleClass = '';
</script>

<button on:click={onClick} class={styleClass}>{text}</button>

<style>
  button {
    background-color: var(--button-color);
    border: none;
    border-radius: 12px;
    color: var(--secondary-color);
    font-family: 'Header';
    font-size: 1.4em;
    padding: 10px 32px;
    min-width: 200px;
    text-align: center;
    text-decoration: none;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  button:hover {
    background-color: var(--button-color-hover);
  }

  button:active {
    background-color: var(--button-color-press);
  }
</style>