<script>
  import Container from '../Elements/Container.svelte';
  import TextButton from '../Elements/TextButton.svelte';
  import { onMount } from "svelte";
  import auth from "../Services/authService";

	let auth0Client;
	onMount(async () => auth0Client = await auth.getClient());

	const login = () => {
    auth.loginWithPopup(auth0Client);
  };
</script>

<Container>
  <section>
    <div class="entry center center-vh">
      <h1>Welcome to BnBreezy App</h1>
      <hr/>
      <h3>Please Login To Access Your Workflow</h3>
      <TextButton onClick={login} styleClass="center-vh" text="Login"/>
    </div>
  </section>
</Container>

<style>
  section {
    padding: 1em;
    min-height: 86vh;
  }

  hr {
    width: 100%;
  }

  h1, h3 {
    text-align: center;
  }

  .entry {
    max-width: 90vw;
    width: 640px;
    display: flex;
    flex-direction: column;
  }
</style>