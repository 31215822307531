<script>
	import DefaultLayout from './Layout/DefaultLayout.svelte';
	import { pages } from './static-content.js';
	import Login from './Pages/Login.svelte';
	import { isLoggedIn } from './Store';

  const page = '/' + (new URLSearchParams(window.location.search).get('page') || '');
  const matchingRoutes = pages.filter(r => r.path.toLocaleLowerCase() === (page).toLocaleLowerCase());
	const component = matchingRoutes[0].component;
	const pageName = matchingRoutes[0].name;
</script>

<main>
	<DefaultLayout>
		{#if $isLoggedIn}
			<svelte:component this={component}/>
		{:else}
			<svelte:component this={Login}/>
		{/if}
	</DefaultLayout>
</main>

<style>
main {
	width: 100%;
}
</style>  
 