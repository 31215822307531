<script>
  export let size = 48;
  export let color = "#fff";
</script>

<svg height={size} width={size} enable-background="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
  <path d="m32 0c-17.645 0-32 14.356-32 32s14.355 32 32 32c17.644 0 32-14.355 32-32s-14.356-32-32-32zm0 4c15.439 0 28 12.56 28 28 0 5.603-1.66 10.821-4.505 15.203-1.455-7.074-6.058-13.197-12.523-16.533 1.889-2.388 3.028-5.396 3.028-8.67 0-7.72-6.28-14-14-14s-14 6.28-14 14c0 3.274 1.139 6.282 3.028 8.67-6.466 3.336-11.068 9.459-12.523 16.533-2.845-4.382-4.505-9.6-4.505-15.203 0-15.44 12.56-28 28-28zm-10 18c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm10 38c-7.82 0-14.898-3.227-19.983-8.414.164-7.855 4.965-14.918 12.158-17.982 2.235 1.512 4.928 2.396 7.825 2.396 2.896 0 5.59-.884 7.825-2.396 7.193 3.064 11.994 10.127 12.158 17.982-5.085 5.187-12.163 8.414-19.983 8.414z" 
    fill={color}/>
</svg>

<style>
  svg:not(:root) {
    width:100%;
    height: auto;
  }
</style>