import createAuth0Client from "@auth0/auth0-spa-js";
import state, { popupOpen } from "../Store/index";
import config from "../../auth_config";

let currentClient = null;
const localStorage = window.localStorage;

const getUser = async (client) => {
  const user = await client.getUser();
  console.log("user: ", user);

  if(user) {
    localStorage.setItem('UserId', user.userId);
  }

  return user;
};

const getClient = async () => {
  if (!currentClient)
    currentClient = await handeCreateClient();
  return currentClient;
}

const handeCreateClient = async () => {
  const client = await auth.createClient();
  const user = await getUser(client);

  state.update( s => ({ ...s, user: user}));

  return client;
};

const createClient = async () => {
  const auth0Client = await createAuth0Client({
    domain: config.domain,
    client_id: config.clientId
  });

  return auth0Client;
};

const loginWithPopup = async (client, options) => {
  popupOpen.set(true);
  
  try {
    await client.loginWithPopup(options);
    let user = await getUser(client);

    state.update( s => ({ ...s, user: user }));

  } catch (e) {
    console.log(e);
  } finally {
    popupOpen.set(false);
  }
};

const logout = (client) => {
  return client.logout();
};

const auth = {
  getClient,
  handeCreateClient,
  createClient,
  loginWithPopup,
  logout
};

export default auth;