<script>
  import state, { isLoggedIn, userAvatar } from '../Store';
  import company from '../static-content.js';
  import LoginIcon from '../Elements/LoginIcon.svelte';
  import LogoutIcon from '../Elements/LogoutIcon.svelte';
  import { onMount } from "svelte";
  import auth from "../Services/authService";

  console.log({ userAvatar: $userAvatar });

	let auth0Client;
	onMount(async () => auth0Client = await auth.getClient());

	const login = () => {
    auth.loginWithPopup(auth0Client);
  };

  const logout = () => {
    auth.logout(auth0Client);
  };

  const iconButtonSize = 40;
</script>

<header>
  <a href="." class="logo">
    <div class="logo-img">
      <img src={company.logo} alt="Logo"/>
    </div>
    <h1>{company.name}</h1>
  </a>

  <div class="buttons">
    {#if !$isLoggedIn}
      <button on:click={login} class="icon-button">
        <LoginIcon size={iconButtonSize} color="#484848"/>
      </button>
    {/if}
    {#if $isLoggedIn}
      {#if !!$userAvatar}
      <div class="avatar">
        <img src={$userAvatar} alt="User Avatar"/>
      </div>
      {/if}
      <button on:click={logout} class="icon-button">
        <LogoutIcon size={iconButtonSize} color="#484848"/>
      </button>
    {/if}
  </div>

</header>

<style>
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
    background-color: var(--header-background-color);
    height: 65px;
    padding: 0 16px;
    border-bottom: var(--header-color-light) 1px solid;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
  }

  h1 {
    color: var(--header-color);
    margin: 10px;
  }

  .logo {
    display: flex;
    flex-direction: row;
    max-height: 92px;
  }

  .logo-img {
    width: 36px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .transparent-button {
    background-color: rgba(0,0,0,0);
    border: 0px;
    cursor: pointer;
  }

  .icon-button {
    border: 0px;
    border-radius: 6px;
    background-color: rgba(0,0,0,0);
    
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .avatar img {
    border-radius: 50%;
  }

  @media screen and (max-width: 600px) {
    .logo-img {
      min-width: 32px;
    }

    .icon-button {    
      min-width: 32px;
      width: 44px;
    }

    header {
      height: 66px;
      padding: 0 8px;
    }
  }
</style>
