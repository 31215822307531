<script>
  import TextButton from '../../Elements/TextButton.svelte';
  import { userEmail } from '../../Store';

  let message = null;

  const go = async () => {
		try{
      const res = await fetch('https://prod-72.westus.logic.azure.com:443/workflows/3ee2e85cb8864bba87949ac464b78328/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JTCucKh53x6s8C68LCWlZvtbCFInhzBkyYFr2NshrJU', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          commandType: 'BnBreezy',
          command: `snapScheduleAndOutputCalendar email ${$userEmail}`
        })
      });
      if (res.status == 200) {
        zip = '';
        message = 'Job Queued Successfully';
      }
    } catch (err) {
      message = err;
    }    
 }
</script>

<div class="form">
  <h2>Snap RR Schedule</h2>
  <TextButton onClick={go} text="Go"/>
  {#if message}
    <p>{message}</p>
  {/if}
</div>

<style>
  .form {
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
</style>